import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd'
import { Navigate, useRoutes } from "react-router-dom";

import Login, { Register, Resetpwd, Protocol } from "./pages/Login";
import Home from './pages/Home';
import List, { OeList } from './pages/List';
import Mine, { Setting, Invoice, Address } from "./pages/Mine";
import Order, { OrderDetail } from "./pages/Mine/Order";
import Carsearch from "./pages/Carsearch";
import PartDtl, { PidDtl } from './pages/PartDtl'
import ConfirmOrder from './pages/ConfirmOrder'
import Cart from './pages/Cart'
import PaySuccess from './pages/PaySuccess'
import WechatPay from './pages/WechatPay'
import Doubt from "./pages/Doubt";
import Buypath from "./pages/Buypath";
import Contactus from "./pages/Contactus";
import Aboutus from "./pages/Aboutus";
import Canvastest from "./pages/Canvastest";
import Morepage from "./pages/Morepage";
import Enquery, { EnquerySuccess } from "./pages/Enquery";

import Vin, { VinDetailPage, Search, PartSearchPage } from '@/pages/Epc'
import KeepAlive from 'react-activation'
import lazyLoad from './lazyLoad';

/* const rootRouter: any = [
	{
		path: "/",
		element: <Navigate to="/home"/>
	},
	{
		path: "/login",
		element: <Login/>,
	},
  {
    path: '/register',
    element: <Register/>
  },
  {
    path: '/resetpwd',
    element: <Resetpwd/>
  },
  {
    path: '/mine',
    element: <Mine/>,
    children: [
      {
        path: '/mine/order',
        element: <Order/>,
      },
      {
        path: '/mine/order/detail',
        element: <OrderDetail/>,
      },
      {
        path: '/mine/setting',
        element: <Setting/>,
      },
      {
        path: '/mine/invoice',
        element: <Invoice/>,
      },
      {
        path: '/mine/address',
        element: <Address/>,
      },

    ]
  },
	{
		path: '/home',
		element: <Home/>
	},
	{
		path: '/vin',
		element: <List from='vin'/>
	},
  {
    path: '/cjh',
    element: <KeepAlive cacheKey="UNIQUE_ID"><List from='cjh'/></KeepAlive>
  },
	{
		path: '/code',
		element: <List from='oe'/>
	},
  {
    path: '/adZone',
    element: <List from='adZone'/>
  },
  {
    path: '/partsZone',
    element: <List from='parts_categories'/>
  },
  { 
    path: '/oe',
    element: <OeList/>
  },
  {
    path: '/carsearch',
    element: <Carsearch/>
  },
	{
		path: '/carsearch/list',
		element: <List from='car'/>
	},
  {
    path: '/class',
    element: <List from="class" />
  },
	{
		path: '/partDtl',
		element: <PartDtl/>
	},
  {
    path: '/pidDtl',
    element: <PidDtl/>
  },
  {
    path: '/oe/pidDtl',
    element: <PidDtl/>
  },
  {
    path: '/cjh/pidDtl',
    element: <PidDtl/>
  },
  {
    path: '/adZone/pidDtl',
    element: <PidDtl/>
  },
  {
    path: '/confirmOrder',
    element: <ConfirmOrder/>
  },
  {
    path: '/cart',
    element: <Cart/>
  },
  {
    path: '/paySuccess',
    element: <PaySuccess/>
  },
  {
    path: '/wechatPay',
    element: <WechatPay/>
  },
  {
    path: '/doubt',
    element: <Doubt/>
  },
  {
    path: '/buypath',
    element: <Buypath/>
  },
  {
    path: '/contactus',
    element: <Contactus/>
  },
  {
    path: '/aboutus',
    element: <Aboutus/>
  },
  {
    path: '/canvastest',
    element: <Canvastest/>
  },
  {
    path: '/morepage/:code',
    element: <Morepage/>
  },
  {
    path: '/epc',
    element: <Search />
  },
  {
    path: '/epc/vin',
    element: <Vin />
  },
  {
    path: '/epc/vin/detail',
    element: <VinDetailPage />
  },
  {
    path: '/epc/part',
    element: <PartSearchPage />
  },
  {
    path: '/oe/part',
    element: <PartSearchPage />
  },
  {
    path: '/enquery',
    element: <Enquery />
  },
  {
    path: '/enquery/success',
    element: <EnquerySuccess />
  },

]; */

const HomeLoad = lazyLoad(lazy(() => import('./pages/Home')))
const CjHList = lazyLoad(lazy(() => import('./pages/List/List')), {from: 'cjh'})


const VinList = lazyLoad(lazy(() => import('./pages/List/List')), {from: 'vin'})
const CodeList = lazyLoad(lazy(() => import('./pages/List/List')), {from: 'oe'})
const AdZoneList = lazyLoad(lazy(() => import('./pages/List/List')), {from: 'adZone'})
const PartsZoneList = lazyLoad(lazy(() => import('./pages/List/List')), {from: 'parts_categories'})
const OeSList = lazyLoad(lazy(() => import('./pages/List/OeList')))
const CarList = lazyLoad(lazy(() => import('./pages/List/List')), {from: 'car'})
const ClassList = lazyLoad(lazy(() => import('./pages/List/List')), {from: 'class'})

const rootRouter: any = [
	{
		path: "/",
		element: <Navigate to="/home"/>
	},
	{
		path: "/login",
		element: lazyLoad(lazy(() => import('./pages/Login/Login')))
	},
  {
    path: '/register',
    element: lazyLoad(lazy(() => import('./pages/Login/Register'))),
  },
  {
    path: '/resetpwd',
    element: lazyLoad(lazy(() => import('./pages/Login/Restpwd'))),
  },
  {
    path: '/protocol/user',
    element: lazyLoad(lazy(() => import('./pages/Login/Protocol')), { from: 'user' }),
  },
  {
    path: '/protocol/privacy',
    element: lazyLoad(lazy(() => import('./pages/Login/Protocol')), { from: 'privacy' }),
  },
  {
    path: '/mine',
    element: <Mine/>,
    children: [
      {
        path: '/mine/order',
        element: lazyLoad(lazy(() => import('./pages/Mine/Order'))),
      },
      {
        path: '/mine/order/detail',
        element: lazyLoad(lazy(() => import('./pages/Mine/Order/Detail'))),
      },
			{
			  path: '/mine/aftersales',
			  element: lazyLoad(lazy(() => import('./pages/Mine/Aftersales'))),
			},
			{
			  path: '/mine/aftersales/detail',
			  element: lazyLoad(lazy(() => import('./pages/Mine/Aftersales/Detail'))),
			},
      {
        path: '/mine/setting',
        element: lazyLoad(lazy(() => import('./pages/Mine/Setting'))),
      },
      {
        path: '/mine/invoice',
        element: lazyLoad(lazy(() => import('./pages/Mine/Invoice'))),
      },
      {
        path: '/mine/address',
        element: lazyLoad(lazy(() => import('./pages/Mine/Address'))),
      },
      {
        path: '/mine/company',
        element: lazyLoad(lazy(() => import('./pages/Mine/Company'))),
      },
      {
        path: '/mine/reconciliation',
        element: lazyLoad(lazy(() => import('./pages/Mine/Reconciliation'))),
      },
      {
        path: '/mine/copewith',
        element: lazyLoad(lazy(() => import('./pages/Mine/Copewith'))),
      },
			{
			  path: '/mine/copewith/detail',
			  element: lazyLoad(lazy(() => import('./pages/Mine/Copewith/Detail'))),
			},
			{
			  path: '/mine/copewith/pay',
			  element: lazyLoad(lazy(() => import('./pages/Mine/Copewith/Pay'))),
			},
			{
			  path: '/mine/invitation',
			  element: lazyLoad(lazy(() => import('./pages/Mine/Invitation'))),
			},
    ]
  },
	{
		path: '/home',
		element: <KeepAlive cacheKey="UNIQUE_ID_HOME" name="UNIQUE_ID_HOME">{HomeLoad}</KeepAlive>
	},
	{
		path: '/vin',
		// element: <List from='vin'/>
    element: <KeepAlive cacheKey="UNIQUE_ID_VIN" name="UNIQUE_ID_VIN">{VinList}</KeepAlive>
	},
  {
    path: '/cjh',
    element: <KeepAlive cacheKey="UNIQUE_ID_CJH" name="UNIQUE_ID_CJH">
      {CjHList}
    </KeepAlive>
  },
	{
		path: '/code',
		// element: <List from='oe'/>
    element: <KeepAlive cacheKey="UNIQUE_ID_CODE" name="UNIQUE_ID_CODE">
      {CodeList}
    </KeepAlive>
	},
  {
    path: '/adZone',
    // element: <List from='adZone'/>
    element: <KeepAlive cacheKey="UNIQUE_ID_ADZONE" name="UNIQUE_ID_ADZONE">
      {AdZoneList}
    </KeepAlive>
  },
  {
    path: '/partsZone',
    // element: <List from='parts_categories'/>
    element: <KeepAlive cacheKey="UNIQUE_ID_PartsZone" name="UNIQUE_ID_PartsZone">
      {PartsZoneList}
    </KeepAlive>
  },
  { 
    path: '/oe',
    element: <KeepAlive cacheKey="UNIQUE_ID_OE" name="UNIQUE_ID_OE">
      {OeSList}
    </KeepAlive>
  },
  {
    path: '/carsearch',
    element: lazyLoad(lazy(() => import('./pages/Carsearch/Carsearch')))
  },
	{
		path: '/carsearch/list',
		// element: <List from='car'/>
    element: <KeepAlive cacheKey="UNIQUE_ID_CAR" name="UNIQUE_ID_CAR">
      {CarList}
    </KeepAlive>
	},
  {
    path: '/class',
    // element: <List from="class" />
    element: <KeepAlive cacheKey="UNIQUE_ID_CLASS" name="UNIQUE_ID_CLASS">
      {ClassList}
    </KeepAlive>
  },
	{
		path: '/partDtl',
		element: lazyLoad(lazy(() => import('./pages/PartDtl/PartDtl')))
	},
  {
    path: '/pidDtl',
    element: lazyLoad(lazy(() => import('./pages/PartDtl/PidDtl')))
  },
  {
    path: '/oe/pidDtl',
    element: lazyLoad(lazy(() => import('./pages/PartDtl/PidDtl')))
  },
  {
    path: '/cjh/pidDtl',
    element: lazyLoad(lazy(() => import('./pages/PartDtl/PidDtl')))
  },
  {
    path: '/adZone/pidDtl',
    element: lazyLoad(lazy(() => import('./pages/PartDtl/PidDtl')))
  },
  {
    path: '/confirmOrder',
    element: lazyLoad(lazy(() => import('./pages/ConfirmOrder')))
  },
  {
    path: '/cart',
    element: lazyLoad(lazy(() => import('./pages/Cart')))
  },
  {
    path: '/paySuccess',
    element: lazyLoad(lazy(() => import('./pages/PaySuccess')))
  },
  {
    path: '/wechatPay',
    element: lazyLoad(lazy(() => import('./pages/WechatPay')))
  },
  {
    path: '/newPay',
    element: lazyLoad(lazy(() => import('./pages/WechatPay/NewPay')))
  },
  {
    path: '/doubt',
    element: lazyLoad(lazy(() => import('./pages/Doubt')))
  },
  {
    path: '/buypath',
    element: lazyLoad(lazy(() => import('./pages/Buypath')))
  },
  {
    path: '/contactus',
    element: lazyLoad(lazy(() => import('./pages/Contactus')))
  },
  {
    path: '/aboutus',
    element: lazyLoad(lazy(() => import('./pages/Aboutus')))
  },
  {
    path: '/canvastest',
    element: lazyLoad(lazy(() => import('./pages/Canvastest')))
  },
  {
    path: '/morepage/:code',
    element: lazyLoad(lazy(() => import('./pages/Morepage')))
  },
  {
    path: '/epc',
    element: <Search/>
  },
  {
    path: '/epc/vin',
    element: <Vin/>
  },
  {
    path: '/epc/vin/detail',
    element: <VinDetailPage/>
  },
  {
    path: '/epc/part',
    element: <PartSearchPage/>
  },
  {
    path: '/oe/part',
    element: lazyLoad(lazy(() => import('./pages/Epc/PartSearchPage')))
  },
  {
    path: '/enquery',
    element: lazyLoad(lazy(() => import('./pages/Enquery')))
  },
  {
    path: '/enquery/success',
    element: lazyLoad(lazy(() => import('./pages/Enquery/EnquerySuccess')))
  },

];

const Router = () => {
	const routes = useRoutes(rootRouter);
  return routes
	 ;
};

export default Router