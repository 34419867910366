import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd';
import type { Locale } from 'antd/es/locale';
import Main from './pages/Main';
import ResInterceptors from './components/ResInterceptors';
import trMessages from './locale/tr'
import ruMessages from './locale/ru'
import enMessages from './locale/en'

import zhCN from 'antd/locale/zh_CN';
import enGB from 'antd/locale/en_GB'
import ruRu from 'antd/locale/ru_RU'
import zhHK from 'antd/locale/zh_HK'
import Tr from 'antd/locale/tr_TR'
import 'dayjs/locale/zh-cn';
import accountApi from './api/accountApi'
import axios from 'axios'
import { AliveScope } from 'react-activation'


let num: number = 1

const App = () => {
  const [messages, setMessages] = useState<any>({})
  const langTemp: any = {
    'zh': {
      locale: 'zh-CN',
      antdLocale: zhCN,
      messages: {}
    },
    'en': {
      locale: 'en',
      antdLocale: enGB,
      messages: enMessages.messages
    },
    'ru': {
      locale: 'ru',
      antdLocale: ruRu,
      messages: ruMessages.messages
    },
    'cht': {
      locale: 'cht',
      antdLocale: zhHK,
      messages: {}
    },
    'tr': {
      locale: 'tr',
      antdLocale: Tr,
      messages: trMessages.messages
    }
  }
  useEffect(() => {
    initLang()
    return () => {
      num = 0
    }
  }, [])

  
  
  const initLang = (): any => {
    let lang: any = 'zh'
    if(window.localStorage.getItem('lang')) {
      lang = window.localStorage.getItem('lang')
    }else if(window.navigator.language.includes('zh')) {
      lang = 'zh'
    }else if(window.navigator.language.includes('en')) {
      lang = 'en'
    }else if(window.navigator.language.includes('ru')) {
      lang = 'ru'
    }else if(window.navigator.language.includes('HK') || window.navigator.language.includes('TW')) {
      lang = 'cht'
    }else if(window.navigator.language.includes('tr') || window.navigator.language.includes('TR')) {
      lang = 'tr'
    }
    num && getLangData(lang)
    window.localStorage.setItem('lang', lang)
    return lang
  }
  const getLangData = (lang: string) => {
    num = 0
    accountApi.getLangData({ xqpLang:  lang }).then((res: any) => {
      let { data } = res
      if(data.code === 1) {
        setMessages({...data.data, ...langTemp[lang].messages})
      }
    })
  }
  if(!Object.keys(messages).length) return null
  return (
    <BrowserRouter basename="/">
      <IntlProvider locale={langTemp[initLang()].locale} messages={messages}>
        <ConfigProvider 
          locale={langTemp[initLang()].antdLocale}
        >
          <ResInterceptors/>
					<Main/>
        </ConfigProvider>
      </IntlProvider>
    </BrowserRouter>
  );
}

export default App;
