import axios from 'axios'
import qs from 'qs'

const vinApi: any = {}

vinApi.vin = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/cars/vin', { params })
}
vinApi.group = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/cars/group', { params })
}
vinApi.structure = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/cars/structure', { params })
}
vinApi.subGroup = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/cars/structure/subs', { params })
}
vinApi.config = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/cars/config/search', { params })
}
vinApi.points = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/cars/structimgs/points", {params})
}
vinApi.partnew = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/cars/vin/part/ai", {params})
}
vinApi.part = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/cars/part", {params})
}

vinApi.config = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/cars/config", {params})
}
vinApi.configSearch = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/cars/config/search", {params})
}
vinApi.tag = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/cars/part/tags", {params})
}
vinApi.getStandardLabel = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/engine/search/stdname_remake", {params})
}

vinApi.baseinfo = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/parts/baseinfo", {params})
}
vinApi.replace = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/parts/replace", {params})
}
vinApi.stocks = (params: {}): Promise<{}> => {
  return axios.post("/v1/cactus/user/zhb/match/stocks/simple", params)
}
vinApi.articles = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/parts/articles", {params})
}
vinApi.applicable = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/parts/generacars", {params})
}
vinApi.pics = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/user/ugc/part/pics", {params})
}
vinApi.removePic = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/user/ugc/part/picdel", {params})
}

vinApi.getMaintenancePoint = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/cars/maintenance/sub/group/struct", {params})
}
vinApi.getMaintenancePart = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/cars/maintenance/sub/group/parts", {params})
}

vinApi.searchKey = (params: {}): Promise<{}> => {
  return axios.post("/v1/api/epc/engine/parts/search_key_synthesis", qs.stringify(params))
}

vinApi.searchHot = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/engine/parts/search_name_v2", {params})
}

vinApi.partSearch = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/engine/parts/search", {params})
}
vinApi.partPrice = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/parts/price", {params})
}
vinApi.buyPartPrice = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/webcst/oe/price", {params})
}
vinApi.setHistory = (params: {}): Promise<{}> => {
  return axios.post("/v1/api/webcst/search/history", params)
}
vinApi.getHistory = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/webcst/search/history", {params})
}
vinApi.getDmsBrand = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/webcst/dms/brand", {params})
}

vinApi.ocr = (params: {}): Promise<{}> => {
  return axios.post("/v1/api/epc/ocr/parse", params)
}
vinApi.ocrPath = '/v1/api/epc/ocr/parse'

export default vinApi