import React, { useContext } from "react";
import styles from './index.module.scss'
import MainContext from '../../stores/stores'
import llq1Png from '../../assets/img/llq1.png'
import llq2Png from '../../assets/img/llq2.png'

const Footer: React.FC<any> = (props) => {
  const { store, setStore } = useContext(MainContext)
  const { userInfo, pageType, isWomala, webData, colorPrimary  } = store

  const goto = (url: string) => {
    if(url) {
      window.open(url, "_blank")
    }
  }
  let otherPage  = pageType === '3' || isWomala ? true : false
  return (
    <div 
      className={styles['footer']} 
      style={{ backgroundColor: otherPage ? '#EDEDED' : colorPrimary, color: otherPage ? '#000000' : '#ffffff' }}
      // style={{ backgroundColor: isWomala  ? '#EDEDED' : '#434343', color: isWomala ? '#000000' : '#ffffff' }}
    >
      <p className="center flex-c-c">
        <i className="iconfont icon-a-24gf-telephone1 mr5" />
        {webData.phone}
      </p>
      <p className="center  flex-c-c">
        <i className="iconfont icon-a-youxiang11 mr5" />
        {webData.email} 
      </p>
      {
        localStorage.getItem("lang") === 'zh' ? (
          <p className="center  flex-c-c" style={{ opacity: .8 }}>
            <span>{webData.filings}</span>
            <span style={{ borderBottom: '1px solid #878787', cursor: 'pointer'  }} onClick={() => goto('https://beian.miit.gov.cn')}>{webData.filings_no}</span>
          </p>
        ): null
      }
      <p className="center flex-c-c" style={{ fontSize: 12 }}>
        {
          otherPage ? <img className="mr5" src={llq1Png} alt="" /> : <img className="mr5" src={llq2Png} alt="" />
        }
        <span
					onClick={() => goto('https://www.007vin.com/')} 
					className="pointer" style={{ display: 'inline-block', borderBottom: '1px solid #878787', opacity: .8 }}>
          零零汽提供技术支持
        </span>
      </p>
    </div>
  )
}

export default Footer