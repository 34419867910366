import React ,{ useEffect } from 'react'
import axios from 'axios'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'

const otherPath = ['/v1/api/epc/cars/structimgs/points', '/v1/api/epc/parts/replace', '/v1/api/epc/engine/search/stdname_remake', '/v1/api/web/user/login/007app']

export let merchantid = '14'
let ResInterceptors: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    init()
  }, [])

  const initLang = () => {
    let lang: any = 'zh'
    if(window.localStorage.getItem('lang')) {
      lang = window.localStorage.getItem('lang')
    }else if(window.navigator.language.includes('zh')) {
      lang = 'zh'
      window.localStorage.setItem('lang', lang)
    }else if(window.navigator.language.includes('en')) {
      lang = 'en'
      window.localStorage.setItem('lang', lang)
    }else if(window.navigator.language.includes('ru')) {
      lang = 'ru'
      window.localStorage.setItem('lang', lang)
    }
    return lang
  }
  const init = () => {
    
    axios.interceptors.request.use((config:any) => {
      config.headers['Xqplang'] = initLang()
      config.headers['ycid'] = localStorage.getItem("ycid") || ""
      config.headers['token'] = localStorage.getItem("token") || ""
      // config.headers['merchantid'] = merchantid
      config.headers['openid'] = localStorage.getItem("openid") || ''
      
      return config
    }, err => {
      return Promise.reject(err)
    })

    axios.interceptors.response.use((response) => {
      if(response.data.code !== 1 && response.data.code !== 4003 && response.data.msg && response.config && response.config.url && !otherPath.includes(response.config.url)) {
        if(response.config.url !== "/v1/api/web/parts/menu/cars") {
          message.warning(response.data.msg)
        }
      }
			
      if(response.data.code === 201) {
        localStorage.removeItem("token")
        localStorage.removeItem('ycid')
        localStorage.removeItem('openid')
        navigate('/login')
      }
      if(response.data.code === 4001) {
        localStorage.removeItem("token")
        localStorage.removeItem('ycid')
        localStorage.removeItem('openid')
        navigate('/login')
      }
      if(response.data.code === 9) {
        localStorage.removeItem("token")
        localStorage.removeItem('ycid')
        localStorage.removeItem('openid')
        navigate('/login')
      }
      return response.data;
    }, err => {
      return Promise.reject(err)
    })
  }
  return null
}

export default ResInterceptors



